import {useNavigate} from "react-router-dom";
import React, {CSSProperties, useContext, useEffect, useRef, useState} from "react";
import MainCustomNavbar from "../../components/MainNavbar";
import {Col, Container, Row} from "react-bootstrap";
import Sidebar from "../../components/Sidebar";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ImageAbrigar from "../../assets/mother_homes/abrigar.png";
import ImageArrowOrange from "../../assets/components/arrow_orange.png";
import ImageMulheres from "../../assets/mother_homes/mulheres.jpg";
import {Carousel} from "react-responsive-carousel";
import video from "*.mp4";
import VideoPrefix from "../../VideoPrefix";
import debora from "../../assets/mother_homes/frames/debora.webp";
import gabi from "../../assets/mother_homes/frames/gabi.webp";
import joana from "../../assets/mother_homes/frames/joana.webp";
import maria from "../../assets/mother_homes/frames/maria.webp";
import ProjectColors from "../../Colors";
import {FaAngleRight, FaAngleLeft} from "react-icons/fa";
import {useGlobalContext} from "../../context/GlobalState";

const mariaVideo = VideoPrefix + "casa_materna_entrevista/maria.mp4";
const gabiVideo = VideoPrefix + "casa_materna_entrevista/gabi.mp4";
const joanaVideo = VideoPrefix + "casa_materna_entrevista/joana.mp4";
const deboraVideo = VideoPrefix + "casa_materna_entrevista/debora.mp4";
export default function Women() {
    const navigate = useNavigate();
    const {screenRatio, isMobile} = useGlobalContext();
    const [innerCarouselWidth, setInnerCarouselWidth] = useState<string>("80%");


    const arrowStyles: CSSProperties = {
        position: 'absolute',
        zIndex: 2,
        top: 'calc(50% - 15px)',
        width: 30,
        height: 30,
        cursor: 'pointer',
    };


    useEffect(() => {
        setInnerCarouselWidth(isMobile ? "90%" : "80%");

        console.log(isMobile)
        console.log(screenRatio)
    }, [screenRatio]);


    const customRenderThumb = (children: any[]) =>
        children.map((item, index) => {
            if (index === 0) {
                return <img src={ImageMulheres} alt={"Foto de todas as mulheres"}/>;
            }
            if (index === 1) {
                return <img src={debora} alt={"Foto de Debora"}/>;
            }

            if (index === 2) {
                return <img src={gabi} alt={"Foto de Gabi"}/>;
            }
            if (index === 3) {
                return <img src={joana} alt={"Foto de Joana"}/>;
            }
            if (index === 4) {
                return <img src={maria} alt={"Foto de Maria"}/>;
            }

            return <img src={ImageMulheres} alt={"Foto de todas as mulheres"}/>;
        });

    return (
        <div
            style={{
                overflow: "hidden",
                height: "100vh",
                width: "100%",
                backgroundColor: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().Black
                    : ProjectColors.getInstance().VeryLightBlue,
            }}
        >
            <MainCustomNavbar activeTab0={false} activeTab2={true}/>
            <main role="main">
                <Container className="memory-page-container">
                    <Row className="mt-5 g-5 ">
                        <Col xs={2} className="sidebar-col">
                            <Sidebar activeTab="mulheres"/>
                        </Col>
                        <Col xs={isMobile ? 10 : 8} className="" style={{
                            paddingLeft: isMobile ? "0px" : "24px",
                        }}>
                            <div
                                className="justify-content-center align-content-center  "
                                style={{height: "80vh"}}
                            >
                                <Carousel
                                    axis={"horizontal"}
                                    dynamicHeight={true}
                                    showIndicators={false}
                                    showStatus={false}
                                    showThumbs={!isMobile.valueOf()}
                                    renderThumbs={isMobile.valueOf() ? undefined : customRenderThumb}

                                    renderArrowPrev={(clickHandler, hasPrev) => {
                                        return (
                                            hasPrev && (
                                                <FaAngleLeft onClick={clickHandler}
                                                             color={ProjectColors.getInstance().isHighContrast
                                                                 ? ProjectColors.getInstance().White
                                                                 : ProjectColors.getInstance().Orange}
                                                             style={{...arrowStyles, left: isMobile ? -10 : 15}}/>)
                                        );
                                    }}
                                    renderArrowNext={(clickHandler, hasNext) => {
                                        return (
                                            hasNext && (

                                                <FaAngleRight onClick={clickHandler}
                                                              color={ProjectColors.getInstance().isHighContrast
                                                                  ? ProjectColors.getInstance().White
                                                                  : ProjectColors.getInstance().Orange}
                                                              style={{...arrowStyles, right: isMobile ? -10 : 15}}/>
                                            )
                                        );
                                    }}
                                >
                                    <div>
                                        <img src={ImageMulheres} alt="" className={" "}
                                             style={{width: innerCarouselWidth}}/>
                                    </div>
                                    <div className={"mx-auto"}
                                         style={{paddingLeft: "10px", paddingRight: "10px", width: innerCarouselWidth}}>
                                        <video className={"img-fluid  "} controls>
                                            <source src={deboraVideo} type="video/mp4"/>
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                    <div className={"mx-auto"}
                                         style={{paddingLeft: "10px", paddingRight: "10px", width: innerCarouselWidth}}>
                                        <video className={"img-fluid"} controls>
                                            <source src={gabiVideo} type="video/mp4"/>
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                    <div className={"mx-auto"}
                                         style={{paddingLeft: "10px", paddingRight: "10px", width: innerCarouselWidth}}>
                                        <video className={"img-fluid"} controls>
                                            <source src={joanaVideo} type="video/mp4"/>
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                    <div className={"mx-auto"}
                                         style={{paddingLeft: "10px", paddingRight: "10px", width: innerCarouselWidth}}>
                                        <video className={"img-fluid "} controls>
                                            <source src={mariaVideo} type="video/mp4"/>
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                </Carousel>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </main>
        </div>
    );
}
