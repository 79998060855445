import MainCustomNavbar from "../../components/MainNavbar";
import {Col, Container, Row} from "react-bootstrap";
import Sidebar from "../../components/Sidebar";
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import Photo0 from "../../assets/mother_homes/casa_antiga.jpg";
import AllBoxes from "../../assets/mother_homes/all_boxes_2.jpg";
import React, {useRef, useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import Column0 from "../../assets/mother_homes/final/column_0.png";
import Column1 from "../../assets/mother_homes/final/column_1.png";
import Column2 from "../../assets/mother_homes/final/column_2.png";
import Column3 from "../../assets/mother_homes/final/column_3.png";
import Column4 from "../../assets/mother_homes/final/column_4.png";
import {log} from "console";
import ProjectColors from "../../Colors";

export default function HousesIndex() {
    const navigate = useNavigate();
    const sliderRef = useRef<HTMLDivElement>(null);
    const sidebarRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    // Adjust slider width according to available space
    useEffect(() => {
        const slider = sliderRef.current;
        const sidebar = sidebarRef.current;
        const container = containerRef.current;

        if (!slider || !sidebar || !container) return;

        const observerCallback: ResizeObserverCallback = (
            entries: ResizeObserverEntry[]
        ) => {
            window.requestAnimationFrame((): void | undefined => {
                if (!Array.isArray(entries) || !entries.length) {
                    return;
                }
                let sidebarWidth = sidebar.getBoundingClientRect().width;
                let containerWidth = container.getBoundingClientRect().width;
                let sliderWidth = containerWidth - sidebarWidth;
                slider.style.setProperty("width", `${sliderWidth}px`, "important");
                slider.style.width = `${sliderWidth}px !important`;
            });
        };
        const resizeObserver = new ResizeObserver(observerCallback);

        resizeObserver.observe(sidebar);
        resizeObserver.observe(container);

        window.addEventListener("error", (e) => {
            if (
                e.message ===
                "ResizeObserver loop completed with undelivered notifications."
            ) {
                const resizeObserverErrDiv = document.getElementById(
                    "webpack-dev-server-client-overlay-div"
                );
                const resizeObserverErr = document.getElementById(
                    "webpack-dev-server-client-overlay"
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute("style", "display: none");
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute("style", "display: none");
                }
            }
        });
    });

    return (
        <div
            style={{
                overflow: "hidden",
                height: "100vh",
                width: "100%",
                backgroundColor: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().Black
                    : ProjectColors.getInstance().VeryLightBlue,
            }}
        >
            <MainCustomNavbar activeTab0={false} activeTab2={true}/>
            <main role="main">
                <Container className="memory-page-container" ref={containerRef}>
                    <Row xs={2} className="mt-5 g-5 memory-page-row">
                        <Col className="sidebar-col" ref={sidebarRef}>
                            <Sidebar activeTab={"caixas"}/>
                        </Col>
                        <Col
                            className="memory-scrollable-wrapper"
                            ref={sliderRef}
                            style={{
                                backgroundColor: ProjectColors.getInstance().isHighContrast
                                    ? ProjectColors.getInstance().DarkGreen
                                    : ProjectColors.getInstance().White,
                            }}
                        >

                            <img src={AllBoxes} alt={"Foto contendo todas as caixas na exposição"}/>

                        </Col>
                    </Row>
                </Container>
            </main>
        </div>
    );
}
